<template>
  <div :id="cat" class="container-xxl advantage">
    <div v-if="(pos===0)" class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 order-lg-1 order-sm-2">
          <span class="image" :style="{ backgroundImage : 'url(' + encodeURI($t('advantages.' + cat + '.image')) + ')' }"></span>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 order-lg-2 order-sm-1">
          <h5>{{ $t('advantages.' + cat + '.title') }}</h5>
          <span v-html="$t('advantages.' + cat + '.text')"></span>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 order-lg-2 order-sm-2">
          <span class="image" :style="{ backgroundImage : 'url(' + encodeURI($t('advantages.' + cat + '.image')) + ')' }"></span>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 order-lg-1 order-sm-1">
          <h5>{{ $t('advantages.' + cat + '.title') }}</h5>
          <span v-html="$t('advantages.' + cat + '.text')"></span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TextImage",
    props: {
        cat: String,
        pos: Number
    },
  };
</script>

<style scoped>
  .image {
    display: block;
    height: 50vh;
    background-color: aliceblue;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
</style>
